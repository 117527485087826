import React, { useContext } from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import Home from "./home/pages/Home";
import City from "./cities/pages/City";
import AboutUs from "./home/components/AboutUs";
import Timeline from "./home/components/Timeline";
import LoginPage from "./home/components/LoginPage";
import SignupPage from "./home/components/SignupPage";
import Registration from "./home/components/Registration";
import ContactUs from "./home/components/ContactUs";
import AddEvent from "./home/components/AddEvent";
import Friends from "./home/components/Friends";
import Snap from "./home/components/Snap";
import SnapchatRedirect from "./home/components/SnapchatRedirect";
import ProfilePage from "./home/components/ProfilePage";
import DataContext from "./shared/context/data-context";
import "./App.css";

function App() {
  const dataCtx = useContext(DataContext);
  const userProfile = dataCtx.userProfile;
  const subscription = userProfile ? userProfile?.subscription : false;

  return (
    <Routes>
      <Route path="/" element={<Snap />} exact />
      <Route path="/student/verification" element={<LoginPage />} exact />
      <Route path="/home" element={<Home />} exact />
      <Route path="/profile" element={<ProfilePage />} exact />
      <Route path="/city/:cityId/:universityId" element={<City />} exact />
      <Route path="/about/:cityId/:universityId" element={<AboutUs />} exact />
      <Route
        path="/contact/:cityId/:universityId"
        element={<ContactUs />}
        exact
      />
      <Route
        path="/timeline/:cityId/:universityId"
        element={<Timeline />}
        exact
      />
      <Route
        path="/friends/:cityId/:universityId"
        element={<Friends />}
        exact
      />
      <Route
        path="/registration/:cityId/:universityId"
        element={<Registration />}
        exact
      />
      <Route
        path="/signup/:cityId/:universityId"
        element={<SignupPage />}
        exact
      />
      <Route
        path="/login/:cityId/:universityId"
        element={<LoginPage />}
        exact
      />
      {subscription && (
        <Route
          path="/event/:cityId/:universityId"
          element={<AddEvent />}
          exact
        />
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
