import React from "react";

import "../../css/LoginTemplateWhatsTheMove.css";
import icons from "../../icons.svg";

function Spinner(props) {
  return (
    <div className="spinner">
      <svg>
        <use href={`${icons}#icon-loader`} r="true"></use>
      </svg>
    </div>
  );
}

export default Spinner;
