import React, { useContext } from "react";
import { Link } from "react-router-dom";

import DataContext from "../../context/data-context";
import "./MobileMenu.css";

const MobileMenu = ({ isActive, onClose, cityId, universityId }) => {
  const dataCtx = useContext(DataContext);
  const user = dataCtx.user;
  const userProfile = dataCtx.userProfile;
  const handleLogout = dataCtx.handleLogout;

  return (
    <div
      id="mobile__menu"
      className={`overlay${isActive ? " overlay--active" : ""}`}
    >
      <Link className="close" onClick={onClose}>
        &times;
      </Link>
      <div className="overlay__content">
        <Link to={`/city/${cityId}/${universityId}`} onClick={onClose}>
          Home
        </Link>
        <Link to={`/contact/${cityId}/${universityId}`} onClick={onClose}>
          Contact
        </Link>
        <Link to={`/friends/${cityId}/${universityId}`} onClick={onClose}>
          Friends
        </Link>
        <Link to={`/timeline/${cityId}/${universityId}`} onClick={onClose}>
          Timeline
        </Link>
        {userProfile?.subscription && (
          <Link to={`/event/${cityId}/${universityId}`} onClick={onClose}>
            Add Event
          </Link>
        )}
        {user ? (
          <Link
            onClick={() => {
              onClose();
              handleLogout();
            }}
          >
            Logout
          </Link>
        ) : (
          <Link to={`/`} onClick={onClose}>
            Sign In
          </Link>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
