import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import DataContext from "../../shared/context/data-context";
import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import Spinner from "../../shared/components/Spinner";
import "./SignupPage.css";
import "./SignupPage.css";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const navigate = useNavigate();
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;
  const user = dataCtx.user;
  const setUser = dataCtx.setUser;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
    navigate(`/city/${cityId}`);
  };

  const handleMicrosoftSignup = async () => {
    const provider = new OAuthProvider("microsoft.com");
    await signInWithPopup(auth, provider);
    navigate(`/city/${cityId}`);
  };

  const handleEmailSignup = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      navigate(`/city/${cityId}`);
    } catch (error) {
      console.error("Error signing up with email and password:", error);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [setUser]);

  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="signup-container">
            <h1 className="signup-title">Create your account</h1>
            <div className="signup-options">
              <div className="email-signup">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="signup-btn email-btn"
                  onClick={handleEmailSignup}
                >
                  Continue
                </button>
                <p>
                  Already have an account?
                  <Link to={`/login/${cityId}`} className="signup-link">
                    Login
                  </Link>
                </p>
              </div>
              <button
                className="signup-btn google-btn"
                onClick={handleGoogleSignup}
              >
                Continue with Google
              </button>
              <button
                className="signup-btn microsoft-btn"
                onClick={handleMicrosoftSignup}
              >
                Continue with Microsoft Account
              </button>
            </div>
            {!loading && user ? (
              <>
                <p>Welcome, {user.email}!</p>
                <button className="signup-btn logout-btn" onClick={logout}>
                  Log out
                </button>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default SignupPage;
