import React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import DataContext from "../../shared/context/data-context";
import Spinner from "../../shared/components/Spinner";
import "./Timeline.css";

function Timeline() {
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );
  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <section className="timeline-section">
            <div className="container py-5">
              <div className="main-timeline-2">
                <div className="timeline-2 left-2">
                  <div className="card">
                    <div className="image-container">
                      <img
                        src={`${process.env.PUBLIC_URL}/../../images/Timeline/Cabo_San_Lucas.JPG`}
                        className="card-img-top"
                        alt="Responsive"
                      />
                    </div>
                    <div className="card-body p-4">
                      <h4 className="fw-bold mb-4">What's The Move Is Born!</h4>
                      <p className="text-muted mb-4">
                        <i className="far fa-clock" aria-hidden="true"></i> July
                        23, 2021
                      </p>
                      <p className="mb-0">
                        In a small town, a group of close-knit friends had just
                        finished their senior year of high school. Eager to
                        celebrate their achievements, they decided to take a
                        memorable trip to Cabo San Lucas, Mexico. The boys
                        looked forward to the beautiful beaches, crystal-clear
                        waters, and exciting nightlife they had heard so much
                        about. As their departure day approached, the
                        anticipation grew. They started planning their
                        activities but soon realized that they didn't even know
                        where to begin. There were so many options available,
                        and they didn't want to miss out on any experiences
                        during their trip. If only there was a website, they
                        thought, that could guide them through the best things
                        to do in Cabo San Lucas. A one-stop resource that could
                        help them organize their trip and make the most of their
                        time in paradise. As fate would have it, they came up
                        with an idea for a website called "WhatsTheMove." The
                        website didn't exist, but the idea of such a thing
                        sounded so beautiful and could be used by so many. The
                        boys imagined a platform where users could explore
                        various activities and experiences, tailoring their trip
                        to their unique interests and preferences. A website
                        that would make planning trips and discovering hidden
                        gems easier than ever before.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="timeline-2 right-2">
                  <div className="card">
                    <div className="image-container">
                      <img
                        src={`${process.env.PUBLIC_URL}/../../images/Timeline/Whats_The_Move_Beginning.jpg`}
                        className="card-img-top"
                        alt="Responsive"
                      />
                    </div>
                    <div className="card-body p-4">
                      <h4 className="fw-bold mb-4"></h4>
                      <p className="text-muted mb-4">
                        <i className="far fa-clock" aria-hidden="true"></i> July
                        15, 2022
                      </p>
                      <p className="mb-0">
                        Whats The Move Officially begins construction. The
                        initial website started in HTML CSS and very simple
                        Javascript.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="timeline-2 left-2">
                  <div className="card">
                    <div className="image-container">
                      <img
                        src={`${process.env.PUBLIC_URL}/../../images/Larger_Red_Cup_Favicon.png`}
                        className="card-img-top"
                        alt="Responsive"
                      />
                    </div>
                    <div className="card-body p-4">
                      <h4 className="fw-bold mb-4"></h4>
                      <p className="text-muted mb-4">
                        <i className="far fa-clock" aria-hidden="true"></i>{" "}
                        March 6, 2023
                      </p>
                      <p className="mb-0">
                        What's The Move officially goes live under the domain
                        WhatsTheMove.us. The first ever events are posted.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="timeline-2 right-2">
                  <div className="card">
                    <div className="image-container">
                      <img
                        src={`${process.env.PUBLIC_URL}/../../images/WhatsTheMoveBackgroundImageShifted2.jpg`}
                        className="card-img-top"
                        alt="Responsive"
                      />
                    </div>
                    <div className="card-body p-4">
                      <h4 className="fw-bold mb-4"> </h4>
                      <p className="text-muted mb-4">
                        <i className="far fa-clock" aria-hidden="true"></i>{" "}
                        March 30, 2023
                      </p>
                      <p className="mb-0">
                        Whats The Move gets plugged into backend and all the
                        data is updated live to any user who can access the web!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="timeline-2 left-2">
                  <div className="card">
                    <div className="image-container">
                      <img
                        src={`${process.env.PUBLIC_URL}/../../images/MainPageBackground.png`}
                        className="card-img-top"
                        alt="Responsive"
                      />
                    </div>
                    <div className="card-body p-4">
                      <h4 className="fw-bold mb-4"></h4>
                      <p className="text-muted mb-4">
                        <i className="far fa-clock" aria-hidden="true"></i>{" "}
                        April 8, 2023
                      </p>
                      <p className="mb-0">
                        The Main page as well as the login page receives a new
                        image designed by the creators of What's The Move
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default Timeline;
