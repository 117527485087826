import { createContext } from "react";

const DataContext = createContext({
  data: [],
  setData: () => {},
  user: null,
  setUser: () => {},
  userProfile: null,
  setUserProfile: () => {},
  fetchUserProfile: () => {},
  setVenueCount: () => {},
  dataFetched: null,
  setDataFetched: () => {},
  authChecked: null,
  setAuthChecked: () => {},
  addNewEvent: () => {},
  handleLogout: () => {},
  checkIfUserPostedEventOnDate: () => {},
  userJustCreated: false,
  setUserJustCreated: () => {},
  oAuthAccessToken: null,
  setOAuthAccessToken: () => {},
  userList: [],
  userDataFetched: null,
});

export default DataContext;
