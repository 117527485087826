import { useContext } from "react";
import { useParams } from "react-router-dom";

import VenueList from "../components/VenueList";
import DataContext from "../../shared/context/data-context";
import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import "../../css/City/City.css";

function City() {
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;

  /*useEffect(() => {
    const fetchData = async () => {
      await dataCtx.setVenueCount(DUMMY_DATA);
      setDataFetched(true); // Set dataFetched to true after the data is fetched
    };
    console.log("loading");
    fetchData();
  }, []);*/

  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );
  const fraternities = (universityItem?.fraternities ?? [])
    .sort((a, b) => {
      const aIdNum = parseInt(a.id.slice(1));
      const bIdNum = parseInt(b.id.slice(1));

      return aIdNum - bIdNum;
    })
    .sort((a, b) => b.count - a.count);

  const schoolOrganizations = (universityItem?.schoolOrganizations ?? [])
    .sort((a, b) => {
      const aIdNum = parseInt(a.id.slice(1));
      const bIdNum = parseInt(b.id.slice(1));

      return aIdNum - bIdNum;
    })
    .sort((a, b) => b.count - a.count);

  const bars = (cityItem?.bars ?? [])
    .sort((a, b) => {
      const aIdNum = parseInt(a.id.slice(1));
      const bIdNum = parseInt(b.id.slice(1));

      return aIdNum - bIdNum;
    })
    .sort((a, b) => b.count - a.count);

  const organizations = (cityItem?.organizations ?? [])
    .sort((a, b) => {
      const aIdNum = parseInt(a.id.slice(1));
      const bIdNum = parseInt(b.id.slice(1));

      return aIdNum - bIdNum;
    })
    .sort((a, b) => b.count - a.count);

  const combinedVenues = [
    ...fraternities,
    ...schoolOrganizations,
    ...bars,
    ...organizations,
  ];

  return (
    <>
      {dataCtx.dataFetched && dataCtx.authChecked ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="grid">
            <VenueList
              venues={combinedVenues}
              cityId={cityId}
              universityId={universityId}
            />
          </div>
        </>
      ) : (
        <h3></h3>
      )}
    </>
  );
}

export default City;

/* <>
      {cityItem ? (
        <header>
          <nav className={cityId}>
            <div className="logo">
              <img
                src={`${process.env.PUBLIC_URL}/${cityItem.logo}`}
                height="10"
                width="10"
                className="logoImage"
                alt="Logo"
              />
              <h3>What's The Move?</h3>
            </div>
            <input type="checkbox" id="click" />
            <label htmlFor="click" className="menu-btn">
              <i className="fas fa-bars"></i>
            </label>
            <ul>
              <li>
                <Link className="active" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/">Timeline</Link>
              </li>
            </ul>
          </nav>

          <div className="grid">
            <VenueList venues={cityItem.venues} cityId={cityId} />
          </div>
        </header>
      ) : (
        <Spinner />
      )}
    </> */
