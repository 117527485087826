import React from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import DataContext from "../../shared/context/data-context";
import Spinner from "../../shared/components/Spinner";
import "./Aboutus.css";

function AboutUs() {
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );

  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="about-wrapper">
            <header className="about-header">
              <img
                src={`${process.env.PUBLIC_URL}/../../images/About/SLCRealMountains.jpg`}
                className="background"
                alt="background"
              />
              <img
                src={`${process.env.PUBLIC_URL}/../../images/About/Treesfinal.jpg`}
                className="foreground"
                alt="foreground"
              />
              <h1 className="about-title">What's The Move?</h1>
            </header>

            <section className="top about-section">
              <h2 className="about-h2">
                <b> Build Connnection And Community </b>
              </h2>
              <p className="about-p-one">
                <img
                  src={`${process.env.PUBLIC_URL}/../../images/About/community.jpg`}
                  height="250"
                  width="250"
                  align="right"
                  alt="community"
                />
                What's The Moves Mission is to connect the world with eachother!
                We are a company based out of Salt Lake City Utah. You can find
                out what is happening in your city or a city you are visiting
                and might not be so familiar with. With what's the move you will
                always be in the loop! Find your community, meet new friends or
                even try something new.
              </p>
            </section>

            <section className="middle about-section">
              <h3 className="about-h3">
                <b> Keeping Everyone Safe </b>
              </h3>
              <p className="about-p-one">
                <img
                  src={`${process.env.PUBLIC_URL}/../../images/About/finalsafety3.png`}
                  height="250"
                  width="250"
                  align="left"
                  alt="safety"
                />
                To protect people from being harmed or stalked, there will be a
                friend system that makes it so only the people you allow will be
                able to see your location. If an app user is not friends with
                you, your identity will be anonymous. You will have to accept a
                friend request if you want others to see what event you are
                going to. Events are also only put on by establishments we have
                manually approved. Click
                <Link to="youtube.com"> </Link>here to learn more about
                establishments.
              </p>
            </section>

            <section className="top about-section">
              <h2 className="about-h2">
                <b> Stay Connected </b>
              </h2>
              <p className="about-p-one">
                <img
                  src={`${process.env.PUBLIC_URL}/../../images/About/stayconnectedcolor.png`}
                  height="250"
                  width="250"
                  align="right"
                  alt="connection"
                />
                On the first week of campus, everyone is desperate to meet new
                people and figure out, "What's the move?" There's only one
                problem - no one has a universal way to promote their events. On
                top of that, people who are looking for friends cannot figure
                out how to find them. There's no incentive to go. You don't know
                if anyone is interested in this event, which de-incentivizes you
                from actually going out and trying to make friends. With What's
                The Move you will always be connected!
              </p>
            </section>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default AboutUs;
