import React, { useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";

import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import DataContext from "../../shared/context/data-context";
import Spinner from "../../shared/components/Spinner";
import "./AddEvent.css";

const AddEvent = () => {
  const [eventDate, setEventDate] = useState("");
  const [eventStartTime, setEventStartTime] = useState("21:00");
  const [eventDescription, setEventDescription] = useState("");
  const [imgSrc, setImgSrc] = useState("placeholder.jpg");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const eventPhoto = useRef(null);
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const addNewEvent = dataCtx.addNewEvent;
  const userProfile = dataCtx.userProfile;
  const DUMMY_DATA = dataCtx.data;
  const checkIfUserPostedEventOnDate = dataCtx.checkIfUserPostedEventOnDate;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );
  const venueItem = [
    ...cityItem.organizations,
    ...cityItem.bars,
    ...universityItem.fraternities,
    ...universityItem.schoolOrganizations,
  ].find((item) => item.host === userProfile.venueName);
  const [eventAddress, setEventAddress] = useState(venueItem.address);

  const handleFileInput = (files) => {
    const file = files[0];
    if (!file.type.startsWith("image/")) {
      alert("Please select an image file.");
      return;
    }

    setSelectedImageFile(file);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImgSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const validateAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_apiKey}`
      );

      if (!response.ok) {
        throw new Error("Error validating address");
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    eventPhoto.current.files = e.dataTransfer.files;
    handleFileInput(eventPhoto.current.files);
  };

  const handleDateChange = (e) => {
    const today = new Date().toISOString().split("T")[0];
    if (e.target.value < today) {
      alert("Please select a current or future date.");
      setEventDate(today);
    } else {
      setEventDate(e.target.value);
    }
  };

  const submitEvent = async () => {
    if (!eventDate) {
      alert("Please select an event date.");
      return;
    }

    if (!(await validateAddress(eventAddress))) {
      alert("Please enter a valid address.");
      return;
    }

    if (!selectedImageFile) {
      alert("Please select an event image.");
      return;
    }

    const hasPosted = await checkIfUserPostedEventOnDate(
      userProfile.id,
      eventDate
    );
    if (hasPosted) {
      alert(
        "You have already posted an event for this date. Either delete or edit already posted event."
      );
      return;
    }

    try {
      const eventData = {
        creatorId: userProfile.id,
        description: eventDescription,
        address: eventAddress,
        date: eventDate,
        startTime: eventStartTime,
        schoolOrCityName: userProfile.schoolOrCityName,
        type: userProfile.type,
        venueName: userProfile.venueName,
      };
      await addNewEvent(eventData, selectedImageFile);
    } catch (error) {
      console.log("Error submitting event:", error);
    }
  };

  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="addEvent-body">
            <div className="addEvent-box">
              <div className="col-left">
                <h1 className="addEvent-h1">Create Event</h1>
                <form id="eventForm">
                  <label htmlFor="eventDate">Event Date:</label>
                  <input
                    type="date"
                    id="eventDate"
                    name="eventDate"
                    required
                    min={new Date().toISOString().split("T")[0]}
                    value={eventDate}
                    onChange={handleDateChange}
                  />
                  <br />
                  <br />

                  <label htmlFor="eventStartTime">Event Start Time:</label>
                  <input
                    type="time"
                    id="eventStartTime"
                    name="eventStartTime"
                    required
                    value={eventStartTime}
                    onChange={(e) => setEventStartTime(e.target.value)}
                  />
                  <br />
                  <br />

                  <label htmlFor="eventAddress">Event Address:</label>
                  <input
                    type="text"
                    id="eventAddress"
                    name="eventAddress"
                    required
                    value={eventAddress}
                    onChange={(e) => setEventAddress(e.target.value)}
                  />
                  <br />
                  <br />

                  <div
                    className="drop-zone"
                    id="dropZone"
                    onClick={() => eventPhoto.current.click()}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    Drag and drop your photo here, or click to choose a file.
                    Square Files Look best.
                    <input
                      type="file"
                      id="eventPhoto"
                      name="eventPhoto"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={eventPhoto}
                      onChange={() => handleFileInput(eventPhoto.current.files)}
                    />
                  </div>

                  <label htmlFor="eventDescription">Event Description:</label>
                  <br />
                  <textarea
                    id="eventDescription"
                    name="eventDescription"
                    rows="4"
                    cols="50"
                    required
                    value={eventDescription}
                    onChange={(e) => setEventDescription(e.target.value)}
                  />
                  <br />
                  <br />

                  <button type="button" id="sendButton" onClick={submitEvent}>
                    Send
                  </button>
                </form>
              </div>

              <div className="col-right">
                <div className="addEvent-image-text-container">
                  <img
                    src={venueItem.logo}
                    className="addEvent-grid-image"
                  ></img>
                  <span>{venueItem.host}</span>
                </div>
                <div
                  className="addEvent-square-image-container"
                  style={{ backgroundImage: `url('${imgSrc}')` }}
                ></div>
                <h3 className="addEvent-venue-h3">People Interested: 100</h3>
                <h4 className="addEvent-venue-h4">{eventDescription}</h4>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default AddEvent;
