import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import DataContext from "../../shared/context/data-context";

import { useHttpClient } from "../../shared/hooks/http-hook";
import "./LoginPage.css";

const redirect =
  "https%3A%2F%2Fauth.snapchat.com%2Foauth2%2Fapi%2Fuser.display_name%20https%3A%2F%2Fauth.snapchat.com%2Foauth2%2Fapi%2Fuser.bitmoji.avatar%20https%3A%2F%2Fauth.snapchat.com%2Foauth2%2Fapi%2Fuser.external_id";

function Snap() {
  const dataCtx = useContext(DataContext);
  const { sendRequest } = useHttpClient();

  /*const buttonId = "snap-login-button";

  useEffect(() => {
    // Initialize the Snapchat Login Kit
    window.snapKitInit = () => {
      const loginConfiguration = {
        clientId: "8902637f-59bf-4508-bc9b-a39a98110e91", // Replace with your Snapchat App's Client ID
        redirectURI: "http://localhost:3000/snapchat-redirect", // Replace with your Redirect URI
        scopeList: JSON.parse(
          document.getElementById("login-kit-config").textContent
        ).SCSDKScopes,
        onLoginSuccess: (result) => {
          console.log("Login successful:", result);
          // Perform necessary actions with the login result, e.g., fetching user data or redirecting to another page
        },
        onLoginFailure: (error) => {
          console.error("Login failed:", error);
          // Handle login failures, e.g., displaying an error message
        },
      };

      // Mount the login button
      if (document.getElementById(buttonId)) {
        window.snap.loginkit.mountButton(buttonId, loginConfiguration);
      }
    };

    // Load the SDK
    const script = document.createElement("script");
    script.src = "https://sdk.snapkit.com/js/v1/login.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // Ensure that the SDK is loaded before calling window.snapKitInit
      window.snapKitInit();
    };
    document.body.appendChild(script);

    return () => {
      // Clean up when the component is unmounted
      document.body.removeChild(script);
    };
  }, [buttonId]);*/
  /*const initializeSnapchatLogin = () => {
    window.snapKitInit = function () {
      const loginButtonIconId = "snap-login-button";

      snap.loginkit.mountButton(loginButtonIconId, {
        clientId: "dc872a60-4363-4f6e-a05b-6764f066c996",
        redirectURI: "http://localhost:3000/snapchat-redirect",
        responseType: "code",
        scopeList: [
          "user.display_name",
          "user.bitmoji.avatar",
          "user.external_id",
        ],
        handleResponse: function () {
          snap.loginkit.fetchUserInfo().then(
            function (result) {
              console.log("User info:", result.data.me);
            },
            function (err) {
              console.error("Error fetching user info", err);
            }
          );
        },
      });
    };

    (function (d, s, id) {
      var js,
        sjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://sdk.snapkit.com/js/v1/login.js";
      sjs.parentNode.insertBefore(js, sjs);
    })(document, "script", "loginkit-sdk");
  };

  useEffect(() => {
    if (dataCtx.authChecked) {
      initializeSnapchatLogin();
    }
  }, [dataCtx.authChecked]);*/

  /*var loginButtonIconId = "snap-login-button";
  var loginParamsObj = {
    // Override this parameter `handleAuthGrantFlowCallback`
    handleAuthGrantFlowCallback: function handleAuthGrantFlowCallback() {
      // TO START THE OAUTH2.0 AUTHORIZATION
      // GRANT FLOW, POINT THIS CALLBACK TO
      // YOUR APPLICATION’S BACKEND HANDLER
    },
    clientId: "your-clientId",
    redirectURI: "your-redirectURI", // REMOVE THIS
    scopeList: ["your-scope(s)"], // REMOVE THIS
  };*/

  /*const OAUTH2_STATE_BYTES = 32;
  const REGEX_PLUS_SIGN = /\+/g;
  const REGEX_FORWARD_SLASH = /\//g;
  const REGEX_EQUALS_SIGN = /=/g;

  const generateRandomBytes = async (size) => {
    const array = new Uint8Array(size);
    return window.crypto.getRandomValues(array);
  };

  const generateBase64UrlEncodedString = (bytesToEncode) => {
    return btoa(String.fromCharCode.apply(null, bytesToEncode))
      .replace(REGEX_PLUS_SIGN, "-")
      .replace(REGEX_FORWARD_SLASH, "_")
      .replace(REGEX_EQUALS_SIGN, "");
  };

  const generateClientState = async () => {
    const randomBytes = await generateRandomBytes(OAUTH2_STATE_BYTES);
    return generateBase64UrlEncodedString(randomBytes);
  };

  const handleSnapchatSignup = async () => {
    const clientId = "dc872a60-4363-4f6e-a05b-6764f066c996";
    const redirectURI = "http://localhost:3000/snapchat-redirect";
    const scopeList = "user.display_name,user.bitmoji.avatar,user.external_id";
    const responseType = "code";

    // Generate a unique state value
    const state = await generateClientState();

    const authURL = `https://accounts.snapchat.com/accounts/oauth2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectURI
    )}&response_type=${responseType}&scope=${encodeURIComponent(
      scopeList
    )}&state=${state}`;

    // Redirect the user to the Snapchat OAuth 2.0 authorization endpoint
    window.location.href = authURL;
  };*/

  const handleSnapchatSignup = () => {
    const url = "https://whatsthemove-3y5d.onrender.com/auth/snapchat";
    window.location.href = url;
  };

  return (
    <>
      {dataCtx.authChecked ? (
        <div className="login-body">
          <div className="login-container">
            <img
              className="login-logo"
              src={`${process.env.PUBLIC_URL}/../../images/Login/Red_Cup_Logo.png`}
              alt="Logo"
            ></img>
            <h2 className="welcome-text">Connect Snapchat to Add Friends!</h2>
            <div className="oauth-container">
              <button
                className="btn-content yellow-btn"
                onClick={handleSnapchatSignup}
              >
                <img
                  width="20px"
                  alt="Snapchat sign-in"
                  src={`${process.env.PUBLIC_URL}/../../images/Login/SnapChat_logo.png`}
                />
                Continue with Snapchat
              </button>
            </div>
            <Link to="/home" className="remind-me-later">
              Remind me later
            </Link>
          </div>
        </div>
      ) : (
        <h2></h2>
      )}
    </>
  );
}

export default Snap;
