import { useContext } from "react";
import { useParams } from "react-router-dom";

import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import Spinner from "../../shared/components/Spinner";
import DataContext from "../../shared/context/data-context";
import "./ContactUs.css";

function ContactUs() {
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );
  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="contact-body">
            <div className="contact-info">
              <img
                src={`${process.env.PUBLIC_URL}/../../images/Login/Red_Cup_Logo.png`}
                alt="Your logo"
              />
              <h2>Contact Us</h2>
              <p>
                <strong>Phone:</strong> (505) 313-1584
              </p>
              <p>
                <strong>Email:</strong> Contact@WhatsTheMove.us
              </p>
              <p>
                <strong>Location:</strong> USA
              </p>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default ContactUs;
