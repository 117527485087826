// pages/HomePage.js
import React from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import MainHeader from "../../shared/components/Navigation/MainHeader";
import MobileMenu from "../../shared/components/Navigation/MobileMenu";
import Spinner from "../../shared/components/Spinner";
import DataContext from "../../shared/context/data-context";
import "./Registration.css";

const Registration = () => {
  const cityId = useParams().cityId;
  const universityId = useParams().universityId;
  const dataCtx = useContext(DataContext);
  const DUMMY_DATA = dataCtx.data;
  const cityItem = DUMMY_DATA.find((item) => item.id === cityId);
  const universityItem = cityItem.universities.find(
    (university) => university.id === universityId
  );
  return (
    <>
      {cityItem ? (
        <>
          <MainHeader
            src={universityItem.logo}
            cityId={cityId}
            universityId={universityId}
          />
          <MobileMenu />
          <div className="registration-container">
            <h1 className="registration-h1">Welcome to OpenAI</h1>
            <div>
              <Link to={`/login/${cityId}`} className="registration-link">
                <button>Login</button>
              </Link>
              <Link to={`/signup/${cityId}`} className="registration-link">
                <button>Sign up</button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Registration;
