import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DataContext from "../../shared/context/data-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./ProfilePage.css";

function ProfilePage() {
  const dataCtx = useContext(DataContext);
  const userProfile = dataCtx.userProfile;
  const setUserProfile = dataCtx.setUserProfile;
  const { sendRequest } = useHttpClient();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const response = await sendRequest(
        "https://whatsthemove-3y5d.onrender.com/api/users/auth/snapchat/login",
        "GET"
      );
      if (!response) {
        return;
      }
      if ("message" in response) {
        return;
      }
      setUserProfile(response);
    };
    checkAuth();
  }, []);

  const handleCreateAccount = async (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const isStudent = event.target.student.value === "yes" ? true : false;

    // Make a PUT request to update the username
    try {
      const response = await sendRequest(
        "https://whatsthemove-3y5d.onrender.com/api/users/changeUsername",
        "PUT",
        {
          "Content-Type": "application/json",
        },
        JSON.stringify({
          userId: userProfile.id,
          newUsername: username,
        })
      );

      if (response.message === "Username updated successfully.") {
        // Update the user profile with the new username
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          username: username,
        }));
        if (isStudent) {
          navigate("/student/verification", true);
        } else {
          navigate("/home", true);
        }
      } else {
        console.error("Failed to update username:", response.message);
      }
    } catch (error) {
      console.error("Failed to update username:", error);
    }
  };

  return (
    <div className="profile-main-container">
      <div className="profile-account-container">
        <div className="profile-content">
          <img
            src={userProfile.photoURL}
            alt="logo"
            className="profile-center-image"
          />
          <h2 className="profile-display-name">{userProfile.name}</h2>
          <p className="profile-small-name">{userProfile.username}</p>

          <form className="profile-form">
            <div className="profile-input-field">
              <input
                type="text"
                name="username"
                placeholder="Enter username"
                required
              />
            </div>

            <label className="profile-question">
              Are you a college student?
            </label>
            <div className="profile-radio-options">
              <label for="yes">Yes</label>
              <input
                type="radio"
                name="student"
                id="yes"
                value="yes"
                required
              />
              <label for="no">No</label>
              <input type="radio" name="student" id="no" value="no" required />
            </div>

            <button
              className="profile-button"
              type="submit"
              onClick={handleCreateAccount}
            >
              Create Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
